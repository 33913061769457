import {ApiRequest} from "~/utils/api-request";
import {echo, echoEvents} from "~/utils/echo";
import cookie from "~/utils/cookie";
import type {User, UserMailNotification} from "~/@types";

let apiRequest = new ApiRequest('get', 'user/info');
apiRequest.onSuccess(data => {
    onSuccess(data);
});

let isSubscribed = false;

let user = reactive<Partial<User> & { init: boolean }>({
    init: false
});

let onSuccess = (data) => {

    for (let key of Object.keys(user)) {
        delete user[key];
    }

    data.init = true;
    Object.assign(user, data);

    if (!isSubscribed && user.id) {

        echo().private(`private-channel`).listen(".bid-added-event", (message) => {
            for (let [key, callback] of Object.entries(echoEvents['bid-added-event'])){
                callback(message);
            }
        }).subscribe();

        echo().private(`private-channel.${user.id}`).listen(".lot-favorite-change-event", (message) => {
            for (let [key, callback] of Object.entries(echoEvents['lot-favorite-change-event'])){
                callback(message);
            }
        }).subscribe();

        echo().private(`private-channel`).listen(".lot-update-status-event", (message) => {
            for (let [key, callback] of Object.entries(echoEvents['lot-update-status-event'])){
                callback(message);
            }
        }).subscribe();

        echo().private(`private-channel`).listen(".lot-extended-event", (message) => {
            for (let [key, callback] of Object.entries(echoEvents['lot-extended-event'])){
                callback(message);
            }
        }).subscribe();

        isSubscribed = true;

    }
}



const loadUserInfo = async() => {

    if (!cookie.authToken.value()) {
        return new Promise(resolve => {
            resolve();
        })
    }

    return apiRequest.request(true);
}

const reloadUserInfo = async () => {
    apiRequest = new ApiRequest('get', 'user/info');
    apiRequest.onSuccess(data => {
        onSuccess(data);
    });

    await loadUserInfo()
}

const getUserNotifications = async ({ notifications }: { notifications: Ref<UserMailNotification> }) => {
    let apiRequest = new ApiRequest('get', 'user/personal/notifications/mails/get');
    apiRequest.onSuccess(data => {
        Object.assign(notifications.value, data);
    });
    apiRequest.onError(error => {
        console.error('getUserNotifications', error);
    })
    await apiRequest.request();
}

const setUserNotifications = async ({notifications, isLoading}: {
    notification: Ref<UserMailNotification>,
    isLoading: Ref<boolean>
}) => {
    let apiRequest = new ApiRequest('post', 'user/personal/notifications/mails/update', {}, notifications.value);
    apiRequest.onSuccess(data => {
        isLoading.value = false;
    });
    await apiRequest.request();
}

export { loadUserInfo, user, reloadUserInfo, getUserNotifications, setUserNotifications };
